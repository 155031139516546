<mat-dialog-content>
    <h2><strong>{{'Warehouses of' | translate}} {{article.codigo}} </strong></h2>
        <table class="table table-bordered">
            <thead class="thead-dark">
                <tr>
                    <th>
                        <mat-label>Item</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Warehouse' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Quantity Initial' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Value Initial' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Update' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Delete' | translate}}</mat-label>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let opening of article.warehouse_values; let i = index">
                    <td data-label="Item">
                        {{i+1}}
                    </td>
                    <td data-label="Warehouse">
                        {{opening.warehouse!.nombre}}
                    </td>
                    <td data-label="Quantity Initial">
                        {{opening.quantity}}
                    </td>
                    <td data-label="Value Initial">
                        {{opening.value}}
                    </td>
                    <td data-label="Update">
                        <button (click)="update(opening)" mat-raised-button>
                            <mat-icon class="black-icon">mode</mat-icon>
                        </button>
                    </td>
                    <td data-label="Delete">
                        <button (click)="deleteWarehouse(opening)" mat-raised-button>
                            <mat-icon class="black-icon">delete</mat-icon>
                        </button>
                    </td>
                </tr>       
            </tbody>
        </table><br>
    <form [formGroup]="warehouseForm">
        <div class="text-margin">
            <span><strong>{{'Warehouse' | translate}}:</strong></span>
            <select class="form-control" formControlName="warehouse">
                <option selected value="">{{'Select warehouse' | translate}}</option>
                <option *ngFor="let warehouse of warehouseList" [ngValue]="warehouse">
                    {{warehouse.nombre}}
                </option>
            </select>
            <span class="form-text text-danger" *ngIf="textValid('warehouse')">{{'You must select a warehouse' | translate}}</span>
        </div>
        <div>
            <span><strong>{{'Quantity Initial' | translate}}:</strong></span>
            <input class="form-control" placeholder="0" type="number" formControlName="quantity">
            <span class="form-text text-danger" *ngIf="textValid('quantity')">{{'You must type a correct quantity' | translate}}</span>
        </div>
        <div>
            <span><strong>{{'Value Initial' | translate}}:</strong></span>
            <input class="form-control" placeholder="0" type="number" formControlName="value">
            <span class="form-text text-danger" *ngIf="textValid('value')">{{'You must type a correct value' | translate}}</span>
        </div>
    </form>
    <div>
        <button class="buttons" mat-raised-button (click)="create()">{{'Add' | translate}}</button>
        <button class="buttons" mat-raised-button mat-dialog-close>{{'Close' | translate}}</button>
    </div>
</mat-dialog-content>
