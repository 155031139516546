import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { ApiService } from "src/app/@graphql/services/api.service";
import * as XLSX from "xlsx";
import { ProviderService } from "../provider/provider.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { map } from "rxjs/internal/operators/map";
import {
  GET_ALL_ACCOUNTING_ACCOUNTS,
  GET_ACCOUNTING_ACCOUNT_BYID,
} from "src/app/@graphql/operations/query";
import { MODIFY_ACCOUNTING_ACCOUNT } from "src/app/@graphql/operations/mutation";
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import {
  GET_DAILY_ADVANCE_PAYMENTS,
  GET_DAILY_INVOICES_AND_PAYMENTS,
  GET_DAILY_PURCHASES_AND_PAYMENTS,
  GET_DAILY_TRANSFERS,
  GET_LAST_30DAYS_ADVANCE_PAYMENT_ACCUMULATED_VALUE,
  GET_LAST_30DAYS_INVOICE_PAYMENT_ACCUMULATED_VALUE,
  GET_LAST_30DAYS_PURCHASE_PAYMENT_ACCUMULATED_VALUE,
  GET_LAST_30DAYS_TRANSFER_ACCUMULATED_VALUE,
} from "src/app/@graphql/operations/dailyReportQueries";
import { IMultipleSheet } from "src/app/@interfaces/Reports/sheets.interface";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { ConverseDateService } from "src/app/@shared/components/services/data-types/converse-date.service";
import { GET_ALL_INVENTORY_MOVEMENTS } from "src/app/@graphql/operations/csvQueries";

@Injectable({
  providedIn: "root",
})
export class ReportsService extends ApiService {
  year = new Date().getFullYear();
  unsubscribe$ = new Subject();

  constructor(
    apollo: Apollo,
    private providerService: ProviderService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private cookieAuthService: CookieAuthService,
    private converseDateService: ConverseDateService
  ) {
    super(apollo);
  }

  getDailyInvoicesAndPayments(
    id_payment: number[],
    initialDate: string,
    endDate: string
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_DAILY_INVOICES_AND_PAYMENTS, {
      id_payment,
      initialDate,
      endDate,
      company,
    }).pipe(
      map((result: any) => {
        return result.getDailyInvoicesAndPayments;
      })
    );
  }

  getDailyPurchasesAndPayments(
    id_payment: number[],
    initialDate: string,
    endDate: string
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_DAILY_PURCHASES_AND_PAYMENTS, {
      id_payment,
      initialDate,
      endDate,
      company,
    }).pipe(
      map((result: any) => {
        return result.getDailyPurchasesAndPayments;
      })
    );
  }

  getDailyTransfers(
    id_payment: number[],
    initialDate: string,
    endDate: string
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_DAILY_TRANSFERS, {
      id_payment,
      initialDate,
      endDate,
      company,
    }).pipe(
      map((result: any) => {
        return result.getDailyTransfers;
      })
    );
  }

  getDailyAdvancePayments(
    id_payment: number[],
    initialDate: string,
    endDate: string
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_DAILY_ADVANCE_PAYMENTS, {
      id_payment,
      initialDate,
      endDate,
      company,
    }).pipe(
      map((result: any) => {
        return result.getDailyAdvancePayments;
      })
    );
  }

  getLastDaysInvoicePaymentAccumulatedValue(
    id_payment: number[],
    initialDate: string
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_LAST_30DAYS_INVOICE_PAYMENT_ACCUMULATED_VALUE, {
      id_payment,
      initialDate,
      company,
    }).pipe(
      map((result: any) => {
        return result.getLast30DaysInvoicePaymentAccumulatedValue;
      })
    );
  }

  getLastDaysPurchasePaymentAccumulatedValue(
    id_payment: number[],
    initialDate: string
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_LAST_30DAYS_PURCHASE_PAYMENT_ACCUMULATED_VALUE, {
      id_payment,
      initialDate,
      company,
    }).pipe(
      map((result: any) => {
        return result.getLast30DaysPurchasePaymentAccumulatedValue;
      })
    );
  }

  getLastDaysTransferAccumulatedValue(
    id_payment: number[],
    initialDate: string
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_LAST_30DAYS_TRANSFER_ACCUMULATED_VALUE, {
      id_payment,
      initialDate,
      company,
    }).pipe(
      map((result: any) => {
        return result.getLast30DaysTransferAccumulatedValue;
      })
    );
  }

  getLastDaysAdvancePaymentAccumulatedValue(
    id_payment: number[],
    initialDate: string
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_LAST_30DAYS_ADVANCE_PAYMENT_ACCUMULATED_VALUE, {
      id_payment,
      initialDate,
      company,
    }).pipe(
      map((result: any) => {
        return result.getLast30DaysAdvancePaymentAccumulatedValue;
      })
    );
  }

  modifyAccountingAccount(
    id_account: number,
    inventory: string,
    service: string,
    tax: string,
    withholding: string,
    ica: string,
    total: string
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(MODIFY_ACCOUNTING_ACCOUNT, {
      id_account,
      inventory,
      service,
      tax,
      withholding,
      ica,
      total,
      company,
    }).pipe(
      map((result: any) => {
        return result.updateAccountingAccounts;
      })
    );
  }

  getAllAccountingAccount() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;

    return this.query(GET_ALL_ACCOUNTING_ACCOUNTS, { company }).pipe(
      map((result: any) => {
        return result.getAllAccountingAccounts;
      })
    );
  }

  getAccountingAccountById(id_account: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ACCOUNTING_ACCOUNT_BYID, {
      id_account,
      company,
    }).pipe(
      map((result: any) => {
        return result.getAccountingAccountById;
      })
    );
  }

  getAllProviders() {
    return new Promise<any[]>((resolve, reject) => {
      this.providerService
        .listProvider(0, null)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: any) => {
          resolve(result.providers);
        });
    });
  }

  getAllInventoryMovements(startDate: string, endDate: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALL_INVENTORY_MOVEMENTS, {
      startDate,
      endDate,
      company,
    }).pipe(
      map((result: any) => {
        return result.getInventoryMovementsByDate;
      })
    );
  }

  async siigoProviders() {
    const providerList = await this.getAllProviders();
    const arrayProviders = this.createArrayThirdParties(providerList);
    return arrayProviders;
  }

  createArrayThirdParties(list: any[]) {
    let arrayThirdParties: any[] = [];
    for (let i = 0; i < list.length; i++) {
      let {
        types_docs,
        doc_nit,
        nombre,
        email,
        direccion,
        country,
        telefono,
        contributorType,
        verification_digit,
        date,
      } = list[i];
      let { id_siigo, id_second } = types_docs[0];
      let { name } = country[0];
      let { id_contributor } = contributorType;
      let name1, name2, lastName1, lastName2, razonSocial, tipPersona;
      name = name === "Colombia" || name === "Venezuela" ? "1" : "";
      if (id_second === "31" || id_second === "50") {
        razonSocial = "S";
        tipPersona = "2";
      } else {
        razonSocial = "N";
        tipPersona = "1";
        let nameParts = nombre.split(" ");
        switch (true) {
          case nameParts.length === 1 || nameParts.length > 4:
            name1 = nameParts[0];
            lastName1 = nameParts[0];
            break;
          case nameParts.length === 2:
            name1 = nameParts[0];
            lastName1 = nameParts[1];
            break;
          case nameParts.length === 3:
            name1 = nameParts[0];
            lastName1 = nameParts[1];
            lastName2 = nameParts[2];
            break;
          case nameParts.length === 4:
            name1 = nameParts[0];
            name2 = nameParts[1];
            lastName1 = nameParts[2];
            lastName2 = nameParts[3];
            break;
          case nameParts.length > 4:
            name1 = nameParts[0];
            name2 = nameParts[1];
            lastName1 = nameParts[2];
            lastName2 = nameParts[3];
            break;
        }
      }
      const formatedDate = this.converseDateService.getDateSeparated(date);
      arrayThirdParties.push({
        "IDENTIFICACIÓN  (OBLIGATORIO)": doc_nit,
        "SUCURSAL  (OBLIGATORIO)": 0,
        "DIGITO DE VERIFICACIÓN": verification_digit,
        NOMBRE: nombre,
        "RAZÓN SOCIAL": razonSocial,
        "PRIMER NOMBRE": name1,
        "SEGUNDO NOMBRE": name2,
        "PRIMER APELLIDO": lastName1,
        "SEGUNDO APELLIDO": lastName2,
        "NÚMERO DE IDENTIFICACIÓN DEL EXTRANJERO": "",
        "CÓDIGO IDENTIFICACIÓN FISCAL": "",
        "NOMBRE DEL CONTACTO": "",
        DIRECCIÓN: direccion,
        PAÍS: name,
        CIUDAD: "",
        ACTIVO: "A",
        "TELÉFONO 1": telefono,
        "TELÉFONO 2": 0,
        "TELÉFONO 3": 0,
        "TELÉFONO 4": 0,
        "TELÉFONO CELULAR": 0,
        FAX: 0,
        "APARTADO AÉREO": 0,
        SEXO: "",
        "AÑO DE CUMPLEAÑOS": 0,
        "MES DE CUMPLEAÑOS": 0,
        "DÍA DE CUMPLEAÑOS": 0,
        "TIPO DE PERSONA": tipPersona,
        "CORREO ELECTRÓNICO": email,
        "CONTACTO DE FACTURACIÓN": "",
        "CORREO ELECT. CONTACTO DE FACTURACIÓN": email,
        "TIPO DE IDENTIFICACIÓN": id_siigo,
        "CLASIFICACIÓN - CLASE DE TERCERO": "C",
        DECLARANTE: "",
        "AUTO RETENEDOR": "",
        "AGENTE RETENEDOR": "",
        "BENEFICIO DIAN RETEIVA COMPRAS": "N",
        "TARIFA DIFERENCIAL RETE IVA VENTAS": "N",
        "PORCENTAJE DIFERENCIAL RETE IVA VENTAS": "",
        "TARIFA DIFERENCIAL RETE IVA COMPRAS": "N",
        "PORCENTAJE DIFERENCIAL RETE IVA COMPRAS": "",
        "CUPO DE CRÉDITO": "",
        "LISTA DE PRECIO": "",
        "FORMA DE PAGO": "",
        CALIFICACIÓN: "",
        "TIPO CONTRIBUYENTE": id_contributor,
        "CÓDIGO ACTIVIDAD ECONÓMICA": "",
        VENDEDOR: "",
        COBRADOR: "",
        "PORCENTAJE DESCUENTO EN VENTAS": "",
        "PERÍODO DE PAGO": "",
        OBSERVACIÓN: "",
        "DÍAS OPTIMISTA": "",
        "DÍAS PESIMISTA": "",
        "CÓDIGO TIPO DE EMPRESA": "",
        "CÓDIGO DE BANCO": "",
        "CÓDIGO INTERNO": "",
        "CÓDIGO OFICINA": "",
        "TIPO DE CUENTA": "",
        "NÚMERO DE CUENTA": "",
        "NIT DEL TITULAR DE LA CUENTA": "",
        "DÍGITO DE VERIFICACIÓN TITULAR DE LA CUENTA": "",
        "NOMBRE DEL TITULAR DE LA CUENTA": "",
        "PAÍS DE LA CUENTA": "",
        "CIUDAD DE LA CUENTA": "",
        "SIGLAS DEPARTAMENTO DE LA CUENTA": "",
        "APLICA RETENCIÓN ICA FACTURA DE VENTA/DEVOLUCIÓN": "",
        "APLICA RETENCIÓN ICA FACTURA DE COMPRA/DEVOLUCIÓN": "",
        "ACEPTA ENVÍO FACTURA POR MEDIO ELECTRÓNICO": "",
        "NOMBRE COMERCIAL": "",
        "CODIGO POSTAL": "",
        "RESPONSABILIDAD FISCAL": "",
        "AÑO APERTURA": formatedDate.year,
        "MES APERTURA": formatedDate.month,
        "DIA APERTURA": formatedDate.day,
        TRIBUTOS: "",
      });
    }
    return arrayThirdParties;
  }

  async getYear() {
    const { value: year } = await Swal.fire({
      title: this.translate.instant("Filter by year"),
      input: "number",
      inputLabel: this.translate.instant("Please type a year"),
      inputValue: this.year,
      inputPlaceholder: this.translate.instant("Year"),
      showCancelButton: true,
    });
    return parseInt(year);
  }

  export(workBook: XLSX.WorkBook, mainSheet: XLSX.WorkSheet, name: string) {
    this.spinner.hide().then((res) => {
      XLSX.utils.book_append_sheet(workBook, mainSheet, "Sheet1");
      XLSX.writeFile(workBook, name);
    });
  }

  exportMultipleSheets(
    workBook: XLSX.WorkBook,
    mainSheets: IMultipleSheet[],
    name: string
  ) {
    this.spinner.hide().then((res) => {
      mainSheets.forEach((mainSheet) => {
        mainSheet.sheet["!cols"] = [{ wpx: 180 }, { wpx: 230 }];
        XLSX.utils.book_append_sheet(
          workBook,
          mainSheet.sheet,
          this.translate.instant(mainSheet.name)
        );
      });
      XLSX.writeFile(workBook, name);
    });
  }
}
