import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import Swal, { SweetAlertResult } from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class AlertsService {
  constructor(private translate: TranslateService, private router: Router) {}

  showErrorAlert(text: string, errors?: string, footer?: string) {
    errors = errors ? errors : "";
    footer = footer ? footer : "";
    return new Promise<SweetAlertResult<any>>((resolve, reject) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: this.translate.instant(text) + " " + errors!,
        footer: footer!,
      }).then((closed) => {
        resolve(closed);
      });
    });
  }

  showSuccessfullAlert(text: string, title?: string, extraMessage?: string) {
    extraMessage = extraMessage ? extraMessage : "";
    return new Promise<SweetAlertResult<any>>((resolve, reject) => {
      Swal.fire(
        title || this.translate.instant("Good"),
        this.translate.instant(text) + extraMessage!,
        "success"
      ).then((closed) => {
        resolve(closed);
      });
    });
  }

  showWarningAlert(text: string) {
    return new Promise<SweetAlertResult<any>>((resolve, reject) => {
      Swal.fire({
        title:this.translate.instant("Warning"),
        text: this.translate.instant(text),
        icon:"warning",
        showCancelButton: true,
        cancelButtonColor: "rgb(0, 0, 0)",
        confirmButtonColor: "rgb(255 84 0)",
        confirmButtonText: "Yes",
      }).then((closed) => {
        resolve(closed);
      });
    });
  }

  showConfirmAlert(text: string) {
    return new Promise<SweetAlertResult<any>>((resolve, reject) => {
      Swal.fire({
        title: this.translate.instant("Warning"),
        text: this.translate.instant(text),
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "rgb(0, 0, 0)",
        confirmButtonColor: "rgb(255 84 0)",
        confirmButtonText: "Yes",
      }).then((result) => {
        resolve(result);
      });
    });
  }

  showSelectAlert(
    title: string,
    options: any[],
    showProperty: string,
    closeButtonText: string
  ) {
    let selection = {} as any;
    return new Promise<any>((resolve, reject) => {
      Swal.fire({
        title: this.translate.instant(title),
        input: "select",
        inputOptions: this.setOptions(options, showProperty),
        inputPlaceholder: this.translate.instant("Select a option"),
        showCancelButton: true,
        cancelButtonText: this.translate.instant(closeButtonText),
        confirmButtonText: "OK",
        allowOutsideClick: false,
      }).then((result: any) => {
        if (result.isConfirmed && result.value) {
          selection = options.find(
            (item) => item[showProperty] === result.value
          );
          resolve(selection);
        } else {
          resolve({});
        }
      });
    });
  }

  showProgressAlert(message: string) {
    Swal.fire({
      title: this.translate.instant("Update in progress"),
      html: message,
      icon: "info",
      showConfirmButton: false,
      allowOutsideClick: false,
      showCancelButton: false,
      showLoaderOnConfirm: true,
    });
  }

  setOptions(allOptions: any[], showProperty: string) {
    const options = {} as any;
    allOptions.forEach((option) => {
      options[option[showProperty]] = option[showProperty];
    });
    return options;
  }

  refresh(template: string, link: string) {
    this.router
      .navigateByUrl(template, { skipLocationChange: true })
      .then(() => {
        this.router.navigate([link]);
      });
  }
}
