import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IInvoicePayments, IPaymentsList } from 'src/app/@interfaces/payments.interface';
import { startWith } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { map } from "rxjs/internal/operators/map";
import { UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TransfersService } from './transfers.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaymentsService } from '../crud-quotation/payments/payments.service';
import { AlertsService } from '../../services/alerts/alerts.service';

@Component({
  selector: "app-transfers",
  templateUrl: "./transfers.component.html",
  styleUrls: ["./transfers.component.css"],
})
export class TransfersComponent implements OnInit, OnDestroy, OnChanges {

  dateForm: UntypedFormControl = this.fb.control(new Date(), Validators.required);
  @Input() disable: boolean = false
  @Input() payment = {} as IInvoicePayments
  customTransferForm:  UntypedFormGroup = this.fb.group({
    transferTo: ["", [Validators.required]],
    transferOf: ["", [Validators.required]],
    value: [0, [Validators.required, Validators.min(0.01), Validators.max(99999999.99)]],
  });
  filteredOptionsTransferTo: Observable<IPaymentsList[]> | undefined;
  filteredOptionsTransferOf: Observable<IPaymentsList[]> | undefined;
  paymentsList: IPaymentsList[] = [];
  unsubscribe$ = new Subject();
  constructor(
    private fb: UntypedFormBuilder,
    private transfersService: TransfersService,
    private spinnerService: NgxSpinnerService,
    private alertsService: AlertsService,
    private paymentsService: PaymentsService
  ) {}

  ngOnInit(): void {
    this.paymentsTypeData();
    this.filterInputs();

  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.payment?.currentValue!) {
      this.disableForms();
    }
  }

  disableForms() {
    if(this.disable) {
      this.customTransferForm.get('transferOf')?.disable({onlySelf: true});
      this.setForms()
    }
  }

  setForms() {
    this.customTransferForm.patchValue({
      transferOf: this.payment.paymentType[0],
      value: this.payment.value,
    })
  }

  filterInputs() {
    this.filteredOptionsTransferTo = this.customTransferForm.get("transferTo")?.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterP(value))
    );
    this.filteredOptionsTransferOf = this.customTransferForm.get("transferOf")?.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterP(value))
    );
  }

  _filterP(value: string): IPaymentsList[] {
    const filterValueP = value.toString().toLowerCase();
    return this.paymentsList.filter((option) =>
      option.description.toLowerCase().toString().includes(filterValueP)
    );
  }

  displayFnPaymentType(payment: any) {
    return payment && payment ? payment.description : undefined;
  }

  registerCustom() {
    this.spinnerService.show();
    if (this.customTransferForm.invalid === true) {
      this.spinnerService.hide();
      this.alertsService.showErrorAlert("Please, fill in all the required fields!");
      return;
    }else {
      const date = this.dateForm.value;
      this.transfersService.createSimpleTransfer(
        this.payment ? "Portfolio" : "Custom",
        new Date(date).toDateString(),
        this.customTransferForm.value.value,
        this.payment ? parseInt(this.payment.id_payinvoice.toString()) : null,
        !this.disable ? parseInt((this.customTransferForm.value.transferOf.id_payment)) : parseInt(this.customTransferForm.get('transferOf')?.getRawValue().id_payment),
        parseInt(this.customTransferForm.value.transferTo.id_payment)
      ).then((confirm) => {
        this.spinnerService.hide();
        this.transfersService.validSuccessfullTransfers([confirm], !this.disable);
      })
    }
  }

  async paymentsTypeData() {
    const cashiersList = await this.paymentsService.getCashierList();
    const banksList = await this.paymentsService.getBankList();
    this.paymentsList = cashiersList.concat(banksList);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
