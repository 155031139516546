import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { ApiService } from "src/app/@graphql/services/api.service";
import { map } from "rxjs/internal/operators/map";
import {
  GETALL_TRANSFERS,
  GET_ADVANCEPAYMENTS_BY_TYPE,
  GET_ALL_ADVENCEPAYMENTS,
  SEARCH_ADVENCEPAYMENTS,
  SEARCH_TRANFERS,
} from "src/app/@graphql/operations/query";
import {
  CREATE_ADVENCEPAYMENT,
  CREATE_TRANSFER,
  DELETE_ADVENCEPAYMENT,
  DELETE_INVOICEPAYMENT_TREASURY,
  DELETE_PURCHASEPAYMENT_TREASURY,
  DELETE_TRANSFER,
  REGISTER_MULTIPLE_TRANSFERS,
  SET_INVOICEPAYMENT_TREASURY,
  SET_PURCHASEPAYMENT_TREASURY,
} from "src/app/@graphql/operations/mutation";
import { IRegisterTransfer } from "src/app/@interfaces/transfers.interface";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Injectable({
  providedIn: "root",
})
export class TreasuryService extends ApiService {
  constructor(
    apollo: Apollo,
    private cookieAuthService: CookieAuthService
  ) {
    super(apollo);
  }

  searchAdvencePayments(value: string, page: number, size: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(SEARCH_ADVENCEPAYMENTS, { value, company, page, size }).pipe(
      map((result: any) => {
        return result.searchAdvencePayments;
      })
    );
  }

  getAllAdvencePayments(page: number, size: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALL_ADVENCEPAYMENTS, { page, size, company }).pipe(
      map((result: any) => {
        return result.getAllAdvencePayments;
      })
    );
  }

  createAdvencePayment(
    id_payment: number,
    id_cliente: number,
    id_provider: number,
    identifier: string,
    type: string,
    date: string,
    value: number,
    id_ref: string
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(CREATE_ADVENCEPAYMENT, {
      id_payment,
      id_cliente,
      id_provider,
      identifier,
      type,
      date,
      value,
      id_ref,
      company,
    }).pipe(
      map((result: any) => {
        return result.createAdvencePayment;
      })
    );
  }

  deleteAdvencePayment(id_treasury: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(DELETE_ADVENCEPAYMENT, { id_treasury, company }).pipe(
      map((result: any) => {
        return result.deleteAdvencePayment;
      })
    );
  }

  getAdvancePaymentsByType(
    id_payments: number[],
    id_customer: number | null,
    id_provider: number | null,
    type: string
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ADVANCEPAYMENTS_BY_TYPE, {
      id_payments,
      id_customer,
      id_provider,
      type,
      company,
    }).pipe(
      map((result: any) => {
        return result.getAdvancePaymentsByType;
      })
    );
  }

  setInvoicePaymentTreasury(
    id_payinvoice: number,
    id_treasury: number,
    value: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(SET_INVOICEPAYMENT_TREASURY, {
      id_payinvoice,
      id_treasury,
      value,
      company,
    }).pipe(
      map((result: any) => {
        return result.setInvoicePaymentTreasury;
      })
    );
  }

  deleteInvoicePaymentTreasury(
    id_payinvoice: number,
    id_treasury: number,
    value: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(DELETE_INVOICEPAYMENT_TREASURY, {
      id_payinvoice,
      id_treasury,
      value,
      company,
    }).pipe(
      map((result: any) => {
        return result.deleteInvoicePaymentTreasury;
      })
    );
  }

  setPurchasePaymentTreasury(
    id_paypurchases: number,
    id_treasury: number,
    value: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(SET_PURCHASEPAYMENT_TREASURY, {
      id_paypurchases,
      id_treasury,
      value,
      company,
    }).pipe(
      map((result: any) => {
        return result.setPurchasePaymentTreasury;
      })
    );
  }

  deletePurchasePaymentTreasury(
    id_paypurchases: number,
    id_treasury: number,
    value: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(DELETE_PURCHASEPAYMENT_TREASURY, {
      id_paypurchases,
      id_treasury,
      value,
      company,
    }).pipe(
      map((result: any) => {
        return result.deletePurchasePaymentTreasury;
      })
    );
  }

  createTransfer(
    type: string,
    date: string,
    custom_value: number,
    id_payinvoice: number | null,
    original_payment: number,
    transferred_payment: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(CREATE_TRANSFER, {
      type,
      date,
      custom_value,
      id_payinvoice,
      original_payment,
      transferred_payment,
      company,
    }).pipe(
      map((result: any) => {
        return result.createTransfer;
      })
    );
  }

  deleteTransfer(id_transfer: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(DELETE_TRANSFER, {
      id_transfer,
      company,
    }).pipe(
      map((result: any) => {
        return result.deleteTransfer;
      })
    );
  }

  getAllTransfers(page: number, size: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GETALL_TRANSFERS, { page, size, company }).pipe(
      map((result: any) => {
        return result.getAllTransfers;
      })
    );
  }

  searchTranfers(value: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(SEARCH_TRANFERS, { value, company }).pipe(
      map((result: any) => {
        return result.searchTranfers;
      })
    );
  }

  createMultipleTransfers(transfers: IRegisterTransfer[]) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(REGISTER_MULTIPLE_TRANSFERS, {
      transfers,
      company,
    }).pipe(
      map((result: any) => {
        return result.registerMultipleTransfers;
      })
    );
  }
}
