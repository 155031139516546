import { Component, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Subject } from "rxjs";
import { OperationalReportingService } from "./operational-reporting.service";
import { CalculateOperationalReportingService } from "src/app/@shared/components/services/reports/calculate-operational-reporting.service";
import * as XLSX from "xlsx";
import { ReportsService } from "../../reports.service";
import { IDailyInvoicePayments } from "src/app/@interfaces/Reports/daily-invoice-payments.interface";
import { NgxSpinnerService } from "ngx-spinner";
import { IPreviousDaysSaleDeposits } from "src/app/@interfaces/Reports/previus-sale-deposits.interface";
import { IOtherCashOutflows } from "src/app/@interfaces/Reports/cash-flows.interface";
import { ICashReceivedDetails } from "src/app/@interfaces/Reports/cash-received.interface";
import { ITotalsByDepartament } from "src/app/@interfaces/Reports/totals-by-departament.interface";
import { ITransactionNumber } from "src/app/@interfaces/Reports/transaction-number.interface";
import { IMultipleSheet } from "src/app/@interfaces/Reports/sheets.interface";
import { IPaymentsList } from "src/app/@interfaces/payments.interface";
import { PaymentsService } from "../../../../@shared/components/dialog/crud-quotation/payments/payments.service";
import { AlertsService } from "src/app/@shared/components/services/alerts/alerts.service";

@Component({
  selector: "app-operational-reporting",
  templateUrl: "./operational-reporting.component.html",
  styleUrls: ["./operational-reporting.component.css"],
})
export class OperationalReportingComponent implements OnInit {
  reportList: Array<any> = ["Daily sales"];
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });
  filterForm: UntypedFormGroup = this.fb.group({
    cashier: [, Validators.required],
  });
  cashierList: Array<IPaymentsList> = [];
  cashierSelected = {} as IPaymentsList;
  cashierName: string = "";
  startDate: string = "";
  endDate: string = "";
  unsubscribe$ = new Subject();
  invoiceDailyClosing = {} as IDailyInvoicePayments;
  previousDaysSaleDeposits = {} as IPreviousDaysSaleDeposits;
  cashPreviousDays: number = 0;
  otherCashOutflows: IOtherCashOutflows[] = [];
  otherCashInflows: IOtherCashOutflows[] = [];
  allCashDetails = {} as ICashReceivedDetails;
  totalsByDepartament = {} as ITotalsByDepartament;
  transactionNumbers = {} as ITransactionNumber;
  @ViewChild("tabs", { static: false }) tabs?: any;
  constructor(
    private fb: UntypedFormBuilder,
    private operationalReportingService: OperationalReportingService,
    private calculateOperationalReportingService: CalculateOperationalReportingService,
    private reportsService: ReportsService,
    private spinner: NgxSpinnerService,
    private alertsService: AlertsService,
    private paymentsService: PaymentsService
  ) {}

  async ngOnInit() {
    this.cashierList = await this.paymentsService.getCashierList();
  }

  async select(report: string) {
    switch (report) {
      case "Daily sales":
        const validations = this.filtersValidation();
        if (validations) {
          this.calculateDailySales();
        } else {
          return;
        }
        break;
    }
  }

  async getCashPreviousDays(filterData: any) {
    const lastDaysInvoiceAccumulated =
      await this.operationalReportingService.getLastDaysInvoicePaymentAccumulatedValue(
        filterData
      );
    const lastDaysPurchaseAccumulated =
      await this.operationalReportingService.getLastDaysPurchasePaymentAccumulatedValue(
        filterData
      );
    const lastDaysTransferAccumulated =
      await this.operationalReportingService.getLastDaysTransferAccumulatedValue(
        filterData
      );
    const lastDaysAdvanceAccumulated = 
      await this.operationalReportingService.getLastDaysAdvancePaymentAccumulatedValue(
        filterData
      )
    const cashPreviousDays =
      lastDaysInvoiceAccumulated +
      lastDaysPurchaseAccumulated +
      lastDaysTransferAccumulated +
      lastDaysAdvanceAccumulated;
    return cashPreviousDays;
  }

  async calculateDailySales() {
    const filterData = await this.captureFilters();
    const invoicePayments =
      await this.operationalReportingService.getDailyInvoicesAndPayments(
        filterData
      );
    const purchasePayments =
      await this.operationalReportingService.getDailyPurchasesAndPayments(
        filterData
      );
    const transfers = await this.operationalReportingService.getDailyTransfers(
      filterData
    );
    const advancePayments = await this.operationalReportingService.getDailyAdvancePayments(
      filterData
    )
    const cashPreviousDays = await this.getCashPreviousDays(filterData);
    const invoceDetails =
      this.calculateOperationalReportingService.calculateDailyClosing(
        invoicePayments
      );
    const previousDaysSaleDeposits =
      this.calculateOperationalReportingService.calculatePreviousDaysSaleDeposits(
        transfers
      );
    const otherCashOutflows =
      this.calculateOperationalReportingService.calculateOtherCashOutflows(
        purchasePayments,
        transfers,
        advancePayments,
        filterData.cashierIds
      );
    const otherCashInflows = this.calculateOperationalReportingService.calculateOtherCashInflows(
      transfers,
      advancePayments,
      filterData.cashierIds
    );
    const allCashDetails =
      this.calculateOperationalReportingService.calculateCashReceivedDetails(
        invoceDetails.dailyClosing,
        previousDaysSaleDeposits,
        cashPreviousDays,
        otherCashOutflows,
        otherCashInflows
      );
    this.invoiceDailyClosing = invoceDetails.dailyClosing;
    this.previousDaysSaleDeposits = previousDaysSaleDeposits;
    this.cashPreviousDays = cashPreviousDays;
    this.otherCashOutflows = otherCashOutflows;
    this.otherCashInflows = otherCashInflows;
    this.allCashDetails = allCashDetails;
    this.totalsByDepartament = invoceDetails.totalsByDepartament;
    this.transactionNumbers = this.countNumberOfTransactions(
      this.invoiceDailyClosing
    );
    this.exportToExcel();
  }

  countNumberOfTransactions(invoiceDailyClosing: IDailyInvoicePayments) {
    const transactionNumbers: ITransactionNumber = {
      cash: this.countTransactions(invoiceDailyClosing.details, "cashPayment"),
      check: 0,
      card: this.countTransactions(invoiceDailyClosing.details, "cardPayment"),
      credit: this.countTransactions(
        invoiceDailyClosing.details,
        "creditPayment"
      ),
      bonds: 0,
      vouchers: 0,
      bank: this.countTransactions(invoiceDailyClosing.details, "bankPayment"),
      advances: this.countTransactions(
        invoiceDailyClosing.details,
        "paymentCrossAdvance"
      ),
      other: 0,
    };
    return transactionNumbers;
  }

  countTransactions(transactionsArray: any[], property: string) {
    let count = 0;
    transactionsArray.forEach((item) => {
      if (item.hasOwnProperty(property) && item[property] > 0) {
        count++;
      }
    });
    return count;
  }

  filtersValidation() {
    if (!this.filterForm.get("cashier")?.value) {
      this.alertsService.showErrorAlert("Please select a cashier");
      return false;
    } else if (!this.range.get("start")?.value) {
      this.alertsService.showErrorAlert("Please select a init date");
      return false;
    } else if (!this.range.get("end")?.value) {
      this.alertsService.showErrorAlert("Please select a end date");
      return false;
    } else {
      return true;
    }
  }

  async captureFilters() {
    this.cashierSelected = this.filterForm.get("cashier")?.value;
    this.cashierName = this.cashierSelected.description;
    this.startDate = this.range.get("start")?.value;
    this.endDate = this.range.get("end")?.value;
    const banks = await this.operationalReportingService.getBankList(
      parseInt(this.cashierSelected.id_user!.toString())
    );
    const allPaymetMethods: IPaymentsList[] = banks.concat([
      this.cashierSelected,
    ]);
    const paymentIds = allPaymetMethods.map((item) =>
      parseInt(item.id_payment.toString())
    );
    const cashierIds = [parseInt(this.cashierSelected.id_payment.toString())];
    this.startDate = this.operationalReportingService.converseDates(
      this.startDate
    );
    this.endDate = this.operationalReportingService.converseDates(this.endDate);
    return {
      paymentIds: paymentIds,
      cashierIds: cashierIds,
      initialDate: this.startDate,
      endDate: this.endDate,
    };
  }

  displayFnSeller(seller: any) {
    return seller && seller ? seller.seller.nombre : undefined;
  }

  displayFnCashier(cashier: any) {
    return cashier && cashier ? cashier.description : undefined;
  }

  exportToExcel() {
    this.spinner.show();
    setTimeout(() => {
      const workBook: XLSX.WorkBook = XLSX.utils.book_new();
      let mainSheets: IMultipleSheet[] = [];
      const cashflowTable = document.getElementById("cashflow");
      const cashclosingTable = document.getElementById("cashclosing");
      const ticketingTable = document.getElementById("ticketing");
      const accounttransactionsTable = document.getElementById(
        "accounttransactions"
      );
      const totalsTable = document.getElementById("totals");
      mainSheets.push({
        sheet: XLSX.utils.table_to_sheet(cashflowTable),
        name: "Cash balancing",
      });
      mainSheets.push({
        sheet: XLSX.utils.table_to_sheet(cashclosingTable),
        name: "Cash closing",
      });
      mainSheets.push({
        sheet: XLSX.utils.table_to_sheet(ticketingTable),
        name: "Ticketing",
      });
      mainSheets.push({
        sheet: XLSX.utils.table_to_sheet(accounttransactionsTable),
        name: "Account movements",
      });
      mainSheets.push({
        sheet: XLSX.utils.table_to_sheet(totalsTable),
        name: "Totals",
      });
      this.reportsService.exportMultipleSheets(
        workBook,
        mainSheets,
        "Report.xlsx"
      );
    }, 2000);
  }
}
