import { gql } from "apollo-angular";
import {
  ARTICLES_OBJECT,
  CREATE_QUOTATION_OBJECT,
  NOTE_OBJECT,
  QUOTATIONS_OBJECT,
  UPDATE_STATUS_QUOTATION_OBJECT,
  USER_OBJECT,
  COMPANY_USER,
  CREATE_DRIVER_OBJECT,
  CREATE_GOAL_OBJECT,
  GOAL_OBJECT,
  CREATE_BILLY_OBJECT,
  REQ_CUSTOMER_OBJECT,
  SELLER_OBJECT,
  MAILLIST_OBJECT,
  PAYMENTS_OBJECT,
  PAYMENT_INVOICE_OBJECT,
  GRUPOART_OBJECT,
  PRESENTATIONS_OBJECT,
  WAREHOUSE_OBJECT,
  CREATE_BILLY_OBJECT_NOTES,
  REQ_PROVIDER_OBJECT,
  CREATE_PURCHASE_INVOICE_OBJECT,
  ACTIVITIES_CIIU_OBJECT,
  PAYMENT_PURCHASES_OBJECT,
  UPDATE_STATUS_PURCHASE_OBJECT,
  USER_SELLER_OBJECT,
  TOKEN_USER_OBJECT,
  ACCOUNTING_ACCOUNTS_OBJECT,
  CUSTOMER_SEGMENT_OBJECT,
  REFUND_OBJECT,
  TREASURY_OBJECT,
  TRANSFER_OBJECT,
  WAREHOUSE_TRANSFER_OBJECT,
  INVENTORY_RECLASSIFICATION_OBJECT,
  PURCHASE_INVOICE_OBJECT,
  WAREHOUSE_OUTPUTS_ENTRIES_OBJECT,
  WAREHOUSE_ARTICLE,
} from "./fragment";

export const UPDATE_ARTICLE_QUANTITIES_TO_0 = gql`
  mutation updateArticleQuantitesTo0($company: String) {
    updateArticleQuantitesTo0(company: $company)
  }
`;

export const UPDATE_AVALIABLE_QUANTITY = gql`
  mutation updateAvaliableQuantity(
    $id_article: ID
    $id_warehouse: ID
    $quantity: Float
    $operation_type: Int
    $company: String
  ) {
    updateAvaliableQuantity(
      id_article: $id_article
      id_warehouse: $id_warehouse
      quantity: $quantity
      operation_type: $operation_type
      company: $company
    ) {
      ...WarehouseArticleObject
    }
  }
  ${WAREHOUSE_ARTICLE}
`;

export const UPDATE_WAREHOUSE_OPENING = gql`
  mutation updateWarehouseOpening(
    $id_warehouse_article: ID
    $quantityInitial: Float
    $valueInitial: Float
    $company: String
  ) {
    updateWarehouseOpening(
      id_warehouse_article: $id_warehouse_article
      quantityInitial: $quantityInitial
      valueInitial: $valueInitial
      company: $company
    ) {
      ...ArticlesObject
    }
  }
  ${ARTICLES_OBJECT}
`;

export const SET_WAREHOUSE_OPENING = gql`
  mutation setWarehouseOpening(
    $article_id: ID
    $warehouse_id: ID
    $quantityInitial: Float
    $valueInitial: Float
    $company: String
  ) {
    setWarehouseOpening(
      article_id: $article_id
      warehouse_id: $warehouse_id
      quantityInitial: $quantityInitial
      valueInitial: $valueInitial
      company: $company
    ) {
      ...ArticlesObject
    }
  }
  ${ARTICLES_OBJECT}
`;

export const DELETE_WAREHOUSE_OPENING = gql`
  mutation deleteWarehouseOpening(
    $id_warehouse_article: ID
    $company: String
  ) {
    deleteWarehouseOpening (
      id_warehouse_article: $id_warehouse_article
      company: $company
    ) {
      ...ArticlesObject
    }
  }
  ${ARTICLES_OBJECT}
`;

export const CANCEL_PURCHASE = gql`
  mutation cancelPurchase($id_invoice: ID!, $company: String) {
    cancelPurchase(id_invoice: $id_invoice, company: $company) {
      ...PurchaseInvoiceObject
    }
  }
  ${PURCHASE_INVOICE_OBJECT}
`;

export const CREATE_WAREHOUSE_OUTPUTS_ENTRIES = gql`
  mutation createWarehouseOutputsAndEntries(
    $id_article: ID
    $type: Int
    $id_warehouse: ID
    $date: String
    $amount: Float
    $cost: Float
    $description: String
    $id_user: ID
    $company: String
  ) {
    createWarehouseOutputsAndEntries(
      id_article: $id_article
      type: $type
      id_warehouse: $id_warehouse
      date: $date
      amount: $amount
      cost: $cost
      description: $description
      id_user: $id_user
      company: $company
    ) {
      ...WarehouseOutputsAndEntriesObject
    }
  }
  ${WAREHOUSE_OUTPUTS_ENTRIES_OBJECT}
`;

export const DELETE_WAREHOUSE_OUTPUTS_ENTRIES = gql`
  mutation deleteWarehouseOutputsAndEntries(
    $id_movement: ID,
    $company: String
  ) {
    deleteWarehouseOutputsAndEntries(
      id_movement: $id_movement,
      company: $company
    ) {
      ...WarehouseOutputsAndEntriesObject
    }
  }
  ${WAREHOUSE_OUTPUTS_ENTRIES_OBJECT}
`;

export const DELETE_INVENTORY_RECLASSIFICATION = gql`
  mutation deleteInventoryReclassification(
    $id_reclassification: ID, 
    $company: String
  ) {
    deleteInventoryReclassification(
      id_reclassification: $id_reclassification, 
      company: $company
    ) {
      ...InventoryReclassificationObject
    }
  }
  ${INVENTORY_RECLASSIFICATION_OBJECT}
`;

export const CREATE_INVENTORY_RECLASSIFICATION = gql`
  mutation createInventoryReclassification(
    $exit_article: ID
    $entry_article: ID
    $exit_warehouse: ID
    $entry_warehouse: ID
    $date: String
    $value: Float
    $id_user: ID
    $company: String 
  ) {
    createInventoryReclassification(
      exit_article: $exit_article
      entry_article: $entry_article
      exit_warehouse: $exit_warehouse
      entry_warehouse: $entry_warehouse
      date: $date
      value: $value
      id_user: $id_user
      company: $company 
    ) {
      ...InventoryReclassificationObject
    }
  }
  ${INVENTORY_RECLASSIFICATION_OBJECT}
`;

export const CREATE_WAREHOUSE_TRANSFER = gql`
  mutation createWarehouseTransfer(
    $id_article: ID,
    $original_warehouse: ID,
    $transferred_warehouse: ID,
    $date: String,
    $value: Float,
    $id_user: ID,
    $company: String
  ) {
    createWarehouseTransfer(
      id_article: $id_article
      original_warehouse: $original_warehouse
      transferred_warehouse: $transferred_warehouse
      date: $date
      value: $value
      id_user: $id_user
      company: $company
    ) {
      ...WarehouseTransferObject
    }
  }
  ${WAREHOUSE_TRANSFER_OBJECT}
`;

export const DELETE_WAREHOUSE_TRANSFER = gql`
  mutation deleteWarehouseTransfer(
    $id_transfer: ID, 
    $company: String
  ) {
    deleteWarehouseTransfer(
      id_transfer: $id_transfer, 
      company: $company
    ) {
      ...WarehouseTransferObject
    }
  }
  ${WAREHOUSE_TRANSFER_OBJECT}
`;

export const REGISTER_MULTIPLE_TRANSFERS = gql`
  mutation registerMultipleTransfers($transfers: [RegisterTransfer], $company: String) {
    registerMultipleTransfers(transfers: $transfers, company: $company) {
      ...TransferObject
    }
  }
  ${TRANSFER_OBJECT}
`;

export const CREATE_TRANSFER = gql`
  mutation createTransfer(
    $type: String,
    $date: String,
    $custom_value: Float,
    $id_payinvoice: ID,
    $original_payment: ID,
    $transferred_payment: ID,
    $company: String
  ) {
    createTransfer(
      type: $type,
      date: $date,
      custom_value: $custom_value,
      id_payinvoice: $id_payinvoice,
      original_payment: $original_payment,
      transferred_payment: $transferred_payment,
      company: $company
    ) {
      ...TransferObject
    }
  }
  ${TRANSFER_OBJECT}
`;

export const DELETE_TRANSFER = gql`
  mutation deleteTransfer(
    $id_transfer: ID,
    $company: String
  ) {
    deleteTransfer(
      id_transfer: $id_transfer,
      company: $company
    ) {
      ...TransferObject
    }
  }
  ${TRANSFER_OBJECT}
`;

export const SET_INVOICEPAYMENT_TREASURY = gql`
  mutation setInvoicePaymentTreasury($id_payinvoice: ID, $id_treasury: ID, $value: Float, $company: String) {
    setInvoicePaymentTreasury(id_payinvoice: $id_payinvoice, id_treasury: $id_treasury, value: $value, company: $company) {
      ...TreasuryObject
    }
  }
  ${TREASURY_OBJECT}
`;

export const DELETE_INVOICEPAYMENT_TREASURY = gql`
  mutation deleteInvoicePaymentTreasury($id_payinvoice: ID, $id_treasury: ID, $value: Float, $company: String) {
    deleteInvoicePaymentTreasury(id_payinvoice: $id_payinvoice, id_treasury: $id_treasury, value: $value, company: $company) {
      ...TreasuryObject
    }
  }
  ${TREASURY_OBJECT}
`;

export const SET_PURCHASEPAYMENT_TREASURY = gql`
  mutation setPurchasePaymentTreasury($id_paypurchases: ID, $id_treasury: ID, $value: Float, $company: String) {
    setPurchasePaymentTreasury(id_paypurchases: $id_paypurchases, id_treasury: $id_treasury, value: $value, company: $company) {
      ...TreasuryObject
    }
  }
  ${TREASURY_OBJECT}
`;

export const DELETE_PURCHASEPAYMENT_TREASURY = gql`
  mutation deletePurchasePaymentTreasury($id_paypurchases: ID, $id_treasury: ID, $value: Float, $company: String) {
    deletePurchasePaymentTreasury(id_paypurchases: $id_paypurchases, id_treasury: $id_treasury, value: $value, company: $company) {
      ...TreasuryObject
    }
  }
  ${TREASURY_OBJECT}
`;

export const CREATE_ADVENCEPAYMENT = gql`
  mutation createAdvencePayment(
    $id_payment: ID,
    $id_cliente: ID,
    $id_provider: ID,
    $identifier: String,
    $type: String,
    $date: String,
    $value: Float,
    $id_ref: String,
    $company: String 
  ) {
    createAdvencePayment(
      id_payment: $id_payment,
      id_cliente: $id_cliente,
      id_provider: $id_provider,
      identifier: $identifier,
      type: $type,
      date: $date,
      value: $value,
      id_ref: $id_ref,
      company: $company 
    ) {
      ...TreasuryObject
    }
  }
  ${TREASURY_OBJECT}
`;

export const DELETE_ADVENCEPAYMENT = gql`
  mutation deleteAdvencePayment(
    $id_treasury: ID,
    $company: String 
  ) {
    deleteAdvencePayment(
      id_treasury: $id_treasury,
      company: $company 
    ) {
      ...TreasuryObject
    }
  }
  ${TREASURY_OBJECT}
`;

export const CREATE_REFUND = gql`
  mutation createRefund(
    $num_invo: Int,
    $id_warehouse: [Int!],
    $id_article: [Int!],
    $code: [String],
    $quantity: [Float!],
    $refund_type: String!,
    $concept: String!,
    $price: [Float!],
    $subtotal: [Float!],
    $discount: [Float!],
    $total: Float!,
    $withholdingTax: Float!,
    $icaTax: Float!,
    $createAt: String!,
    $dueDate: String!,
    $company: String,
    $id_user: Int
  ) {
    createRefund(
      num_invo: $num_invo,
      id_warehouse: $id_warehouse,
      id_article: $id_article,
      code: $code,
      quantity: $quantity,
      refund_type: $refund_type,
      concept: $concept,
      price: $price,
      subtotal: $subtotal,
      discount: $discount,
      total: $total,
      withholdingTax: $withholdingTax,
      icaTax: $icaTax,
      createAt: $createAt,
      dueDate: $dueDate,
      company: $company,
      id_user: $id_user
    ) {
      ...RefundObject
    }
  }
  ${REFUND_OBJECT}
`

export const CREATE_CUSTOMER_SEGMENT = gql`
  mutation createCustomerSegments($nombre: String, $company: String) {
    createCustomerSegments(nombre: $nombre, company: $company) {
      ...CustomerSegmentObject
    }
  }
  ${CUSTOMER_SEGMENT_OBJECT}
`;

export const REMOVE_CUSTOMER_SEGMENT = gql`
  mutation removeCustomerSegments($id: ID, $company: String) {
    removeCustomerSegments(id: $id, company: $company) {
      ...CustomerSegmentObject
    }
  }
  ${CUSTOMER_SEGMENT_OBJECT}
`;

export const MODIFY_ACCOUNTING_ACCOUNT = gql`
  mutation updateAccountingAccounts(
    $id_account: ID,
    $inventory: String,
    $service: String,
    $tax: String,
    $withholding: String,
    $ica: String,
    $total: String,
    $company: String
  ) {
    updateAccountingAccounts(
      id_account: $id_account,
      inventory: $inventory,
      service: $service,
      tax: $tax,
      withholding: $withholding,
      ica: $ica,
      total: $total,
      company: $company
    ) {
      ...AccountingAccountsObject
    }
  }
  ${ACCOUNTING_ACCOUNTS_OBJECT}
`

export const CREATE_USER_SELLER = gql`
  mutation createUserSeller(
    $id_user: Int,
    $id_seller: Int,
    $company: String
  ) {
    createUserSeller(
      id_user: $id_user,
      id_seller: $id_seller,
      company: $company
    ) {
      ...UserSellerObject
    }
  }
  ${USER_SELLER_OBJECT}
`;

export const CREATE_ACTIVITY = gql`
  mutation createActivity(
    $id_city: ID,
    $ica_code: String,
    $activity_code: String,
    $activity_description: String,
    $actual_rate: Float
  ) {
    createActivity(
      id_city: $id_city,
      ica_code: $ica_code,
      activity_code: $activity_code,
      activity_description: $activity_description,
      actual_rate: $actual_rate
    ) {
      ...ActivitiesCiiuObject
    }
  }
  ${ACTIVITIES_CIIU_OBJECT}
`;

export const UPDATE_ACTIVITY = gql`
  mutation updateActivity(
    $id_activity: ID,
    $id_city: ID,
    $ica_code: String,
    $activity_code: String,
    $activity_description: String,
    $actual_rate: Float
  ) {
    updateActivity(
      id_activity: $id_activity,
      id_city: $id_city,
      ica_code: $ica_code,
      activity_code: $activity_code,
      activity_description: $activity_description,
      actual_rate: $actual_rate
    ) {
      ...ActivitiesCiiuObject
    }
  }
  ${ACTIVITIES_CIIU_OBJECT}
`;

export const CREATE_PURCHASE = gql`
  mutation createPurchase(
    $written_invoice: String!,
    $id_provider: Int!,
    $tax: Float!,
    $withholdingTax: Float!,
    $icaTax: Float!,
    $customerCity: String,
    $providerCity: String,
    $id_warehouse: [Int!],
    $codigo: [String!],
    $quantity: [Int!],
    $price: [Float!],
    $discount: [Float!],
    $subtotal: [Float!],
    $total: [Float!],
    $createdAt: String!,
    $dueDate: String!,
    $tax_incl: String!,
    $clause: String!,
    $xml: String,
    $id_user: Int,
    $company: String
  ) {
    createPurchase(
      written_invoice: $written_invoice,
      id_provider: $id_provider,
      tax: $tax,
      withholdingTax: $withholdingTax,
      icaTax: $icaTax,
      customerCity: $customerCity,
      providerCity: $providerCity,
      id_warehouse: $id_warehouse,
      codigo: $codigo,
      quantity: $quantity,
      price: $price,
      discount: $discount,
      subtotal: $subtotal,
      total: $total,
      createdAt: $createdAt,
      dueDate: $dueDate,
      tax_incl: $tax_incl,
      clause: $clause,
      xml: $xml,
      id_user: $id_user,
      company: $company
    ) {
      ...CreatePurchaseInvoiceObject
    }
  }
  ${CREATE_PURCHASE_INVOICE_OBJECT}
`;

export const UPDATE_PURCHASE = gql`
  mutation updatePurchase(
    $written_invoice: String!,
    $id_invoice: ID!,
    $tax: Float,
    $withholdingTax: Float!,
    $icaTax: Float!,
    $customerCity: String,
    $providerCity: String,
    $id_provider: Int,
    $id_purchases: [Int!],
    $id_purchaseDelete: [Int!],
    $id_warehouse: [Int],
    $codigo: [String],
    $quantity: [Int],
    $price: [Float],
    $discount: [Float],
    $subtotal: [Float],
    $total: [Float],
    $createdAt: String,
    $dueDate: String!,
    $tax_incl: String!,
    $clause: String!,
    $id_user: Int,
    $company: String
  ) {
    updatePurchase(
      written_invoice: $written_invoice,
      id_invoice: $id_invoice,
      tax: $tax,
      withholdingTax: $withholdingTax,
      icaTax: $icaTax,
      customerCity: $customerCity,
      providerCity: $providerCity,
      id_provider: $id_provider,
      id_purchases: $id_purchases,
      id_purchaseDelete: $id_purchaseDelete,
      id_warehouse: $id_warehouse,
      codigo: $codigo,
      quantity: $quantity,
      price: $price,
      discount: $discount,
      subtotal: $subtotal,
      total: $total,
      createdAt: $createdAt,
      dueDate: $dueDate,
      tax_incl: $tax_incl,
      clause: $clause,
      id_user: $id_user,
      company: $company
    ) {
      ...CreatePurchaseInvoiceObject
    }
  }
  ${CREATE_PURCHASE_INVOICE_OBJECT}
`;

//Provider mutation
export const CREATE_PROVIDER = gql`
  mutation createProvider(
    $doc_nit: String!,
    $id_docs: ID,
    $nombre: String!,
    $direccion: String!,
    $telefono: String!,
    $email: String!,
    $id_country: Int!,
    $city: String!,
    $id_contributor: ID,
    $id_regime: ID,
    $id_declarant: ID,
    $id_type: ID,
    $activity_code: String,
    $company: String,
    $id_user: Int
  ) {
    createProvider(
      doc_nit: $doc_nit,
      id_docs: $id_docs,
      nombre: $nombre,
      direccion: $direccion,
      telefono: $telefono,
      email: $email,
      id_country: $id_country,
      city: $city,
      id_contributor: $id_contributor
      id_regime: $id_regime,
      id_declarant: $id_declarant,
      id_type: $id_type,
      activity_code: $activity_code,
      company: $company,
      id_user: $id_user
    ) {
      ...RequestProviderObj
    }
  }
  ${REQ_PROVIDER_OBJECT}
`;

export const UPDATE_PROVIDER = gql`
  mutation updateProvider(
    $id_provider: ID!,
    $doc_nit: String,
    $id_docs: ID,
    $nombre: String,
    $direccion: String,
    $telefono: String,
    $email: String,
    $id_country: Int,
    $city: String!,
    $id_contributor: ID
    $id_regime: ID,
    $id_declarant: ID,
    $id_type: ID,
    $activity_code: String,
    $company: String
  ) {
    updateProvider(
      id_provider: $id_provider,
      doc_nit: $doc_nit,
      id_docs: $id_docs,
      nombre: $nombre,
      direccion: $direccion,
      telefono: $telefono,
      email: $email,
      id_country: $id_country,
      city: $city,
      id_contributor: $id_contributor,
      id_regime: $id_regime,
      id_declarant: $id_declarant,
      id_type: $id_type,
      activity_code: $activity_code,
      company: $company
    ) {
      ...RequestProviderObj
    }
  }
  ${REQ_PROVIDER_OBJECT}
`;

export const SEND_REPORTS_DASHBOARD = gql`
  mutation sendReportDashboardEmail($file: String, $copy: String) {
    sendReportDashboardEmail(file: $file, copy: $copy)
  }
`;

export const SEND_PAYMENT_MAIL = gql`
  mutation sendPaymentsMail($id_factura: ID, $file: String, $company: String) {
    sendPaymentsMail(id_factura: $id_factura, file: $file, company: $company)
  }
`;
export const SEND_PAYMENT_PURCHASE_MAIL = gql`
mutation sendPaymentPurchasesMail($id_invoice: ID, $file: String, $company: String){
  sendPaymentPurchasesMail(id_invoice: $id_invoice, file: $file, company: $company)
}
`;

export const UPDATE_PAYMENT_STATUS = gql`
  mutation updatePaymentStatus(
    $id_payinvoice: ID,
    $p_vendor: Boolean,
    $cancelled: Boolean,
    $company: String
  ) {
    updatePaymentStatus(
      id_payinvoice: $id_payinvoice,
      p_vendor: $p_vendor,
      cancelled: $cancelled,
      company: $company
    ) {
      ...PayInvoiceObject
    }
  }
  ${PAYMENT_INVOICE_OBJECT}
`;

export const UPDATE_PAYMENT_PURCHASES_STATUS = gql`
mutation updatePaymentPurchasesStatus($id_paypurchases: ID, $p_vendor: Boolean, $cancelled: Boolean, $company: String){
  updatePaymentPurchasesStatus(id_paypurchases: $id_paypurchases, p_vendor: $p_vendor, cancelled: $cancelled, company: $company){
    ...PayPurchasesObject
  }
}
${PAYMENT_PURCHASES_OBJECT}
`;

export const CREATE_WAREHOUSE = gql`
  mutation createWarehouse($nombre: String, $company: String) {
    createWarehouse(nombre: $nombre, company: $company) {
      ...WarehouseObject
    }
  }
  ${WAREHOUSE_OBJECT}
`;

export const REMOVE_WAREHOUSE = gql`
  mutation removeWarehouse($id: ID, $company: String) {
    removeWarehouse(id: $id, company: $company) {
      ...WarehouseObject
    }
  }
  ${WAREHOUSE_OBJECT}
`;

export const CREATE_GROUPART = gql`
  mutation createGroups($nombre: String, $id_type: Int, $company: String) {
    createGroups(nombre: $nombre, id_type: $id_type, company: $company) {
      ...GrupoArtObject
    }
  }
  ${GRUPOART_OBJECT}
`;

export const REMOVE_GROUPART = gql`
  mutation removeGroups($id: ID, $company: String) {
    removeGroups(id: $id, company: $company) {
      ...GrupoArtObject
    }
  }
  ${GRUPOART_OBJECT}
`;

export const CREATE_PRESENTATIONS = gql`
  mutation createPresentations($value: String, $company: String) {
    createPresentations(value: $value, company: $company) {
      ...PresentationsObject
    }
  }
  ${PRESENTATIONS_OBJECT}
`;

export const REMOVE_PRESENTATIONS = gql`
  mutation removePresentations($id: ID, $company: String) {
    removePresentations(id: $id, company: $company) {
      ...PresentationsObject
    }
  }
  ${PRESENTATIONS_OBJECT}
`;

export const ASSING_PAYMENTS = gql`
  mutation assignPayment(
    $id_factura: ID!,
    $id_payment: ID!,
    $type: ID!,
    $company: String
  ) {
    assignPayment(
      id_factura: $id_factura,
      id_payment: $id_payment,
      type: $type,
      company: $company
    ) {
      ...PaymentsObject
    }
  }
  ${PAYMENTS_OBJECT}
`;

export const ASSING_PAYMENTS_PURCHASE = gql`
mutation assignPaymentPurchase($id_invoice: ID!, $id_payment: ID!, $type: ID!, $company: String){
  assignPaymentPurchase(id_invoice: $id_invoice, id_payment: $id_payment, type: $type, company: $company){
    ...PaymentsObject
  }
}
${PAYMENTS_OBJECT}
`;

export const CREATE_INVOICE_PAYMENTS = gql`
  mutation invoicePayments(
    $id_factura: ID,
    $id_payment: ID,
    $type: String
    $date: String,
    $value: Float,
    $id_ref: String,
    $payment_description: String,
    $company: String
  ) {
    invoicePayments(
      id_factura: $id_factura,
      id_payment: $id_payment,
      type: $type,
      date: $date,
      value: $value,
      id_ref: $id_ref,
      payment_description: $payment_description,
      company: $company
    ) {
      ...PayInvoiceObject
    }
  }
  ${PAYMENT_INVOICE_OBJECT}
`;

export const CREATE_PURCHASES_PAYMENTS = gql`
mutation invoicePaymentPurchases($id_invoice: ID, $id_payment: ID, $type: String, $date: String, $value: Float, $id_ref: String, $company: String){
  invoicePaymentPurchases(id_invoice: $id_invoice, id_payment: $id_payment, type: $type, date: $date, value: $value, id_ref: $id_ref, company: $company){
    ...PayPurchasesObject
  }
}
${PAYMENT_PURCHASES_OBJECT}
`;

export const REMOVE_PURCHASE_PAYMENTS = gql`
mutation delPurchasePayments($id: ID!, $company: String){
  delPurchasePayments(id: $id, company: $company){
    ...PayPurchasesObject
  }
}
${PAYMENT_PURCHASES_OBJECT}
`;

export const REMOVE_INVOICE_PAYMENTS = gql`
  mutation delInvoicePayments($id: ID!, $company: String) {
    delInvoicePayments(id: $id, company: $company) {
      ...PayInvoiceObject
    }
  }
  ${PAYMENT_INVOICE_OBJECT}
`;

export const CREATE_PAYMENTS = gql`
  mutation createPayments(
    $type: String!,
    $description: String!,
    $id_user: Int,
    $company: String
  ) {
    createPayments(
      type: $type,
      description: $description,
      id_user: $id_user,
      company: $company
    ) {
      ...PaymentsObject
    }
  }
  ${PAYMENTS_OBJECT}
`;

export const UPDATE_PAYMENTS = gql`
  mutation updatePayments(
    $id: ID!,
    $type: String!,
    $description: String!,
    $company: String
  ) {
    updatePayments(
      id: $id,
      type: $type,
      description: $description,
      company: $company
    ) {
      ...PaymentsObject
    }
  }
  ${PAYMENTS_OBJECT}
`;

export const REMOVE_PAYMENTS = gql`
  mutation removePayments($id_factura: ID!, $id_payment: ID!, $ids_delete: [Int], $company: String) {
    removePayments(id_factura: $id_factura, id_payment: $id_payment, ids_delete: $ids_delete, company: $company) {
      ...PaymentsObject
    }
  }
  ${PAYMENTS_OBJECT}
`;

export const REMOVE_PAYMENTS_PURCHASE = gql`
  mutation removePaymentsPurchase($id_invoice: ID!, $id_payment: ID!, $ids_delete: [Int], $company: String) {
    removePaymentsPurchase(id_invoice: $id_invoice, id_payment: $id_payment, ids_delete: $ids_delete, company: $company) {
      ...PaymentsObject
    }
  }
  ${PAYMENTS_OBJECT}
`;

export const CREATE_MAIL_LIST = gql`
  mutation createMailList(
    $mail: [String],
    $group_mails: String,
    $company: String
  ) {
    createMailList(mail: $mail, group_mails: $group_mails, company: $company) {
      ...MailListObject
    }
  }
  ${MAILLIST_OBJECT}
`;

export const UPDATE_MAIL_LIST = gql`
  mutation updateMail(
    $id: ID,
    $mail: [String],
    $group_mails: String,
    $company: String
  ) {
    updateMail(
      id: $id,
      mail: $mail,
      group_mails: $group_mails,
      company: $company
    ) {
      ...MailListObject
    }
  }
  ${MAILLIST_OBJECT}
`;

export const REMOVE_MAIL_LIST = gql`
  mutation removeMail($id: ID, $company: String) {
    removeMail(id: $id, company: $company) {
      ...MailListObject
    }
  }
  ${MAILLIST_OBJECT}
`;

export const CREATE_SELLER = gql`
  mutation createSeller(
    $doc_nit: String!,
    $id_docs: ID,
    $nombre: String,
    $email: String,
    $id_country: Int,
    $company: String
  ) {
    createSeller(
      doc_nit: $doc_nit,
      id_docs: $id_docs,
      nombre: $nombre,
      email: $email,
      id_country: $id_country,
      company: $company
    ) {
      ...SellerObject
    }
  }
  ${SELLER_OBJECT}
`;

export const UPDATE_SELLER = gql`
  mutation updateSeller(
    $id_seller: ID!,
    $doc_nit: String!,
    $id_docs: ID,
    $nombre: String,
    $email: String,
    $id_country: Int,
    $company: String
  ) {
    updateSeller(
      id_seller: $id_seller,
      doc_nit: $doc_nit,
      id_docs: $id_docs,
      nombre: $nombre,
      email: $email,
      id_country: $id_country,
      company: $company
    ) {
      ...SellerObject
    }
  }
  ${SELLER_OBJECT}
`;

export const CREATE_BILLY = gql`
  mutation saveBillyInvoice(
    $id_fact: String,
    $id_billy: String,
    $invoice_number: String,
    $invoiced_date: String,
    $company: String
  ) {
    saveBillyInvoice(
      id_fact: $id_fact,
      id_billy: $id_billy,
      invoice_number: $invoice_number,
      invoiced_date: $invoiced_date,
      company: $company
    ) {
      ...BillyObjectC
    }
  }
  ${CREATE_BILLY_OBJECT}
`;

export const CREATE_BILLY_NOTES = gql`
  mutation saveBillyNote(
    $id_note: String,
    $id_billy: String,
    $note_number: String,
    $company: String
  ) {
    saveBillyNote(
      id_note: $id_note,
      id_billy: $id_billy,
      note_number: $note_number,
      company: $company
    ) {
      ...BillyObjectNotesC
    }
  }
  ${CREATE_BILLY_OBJECT_NOTES}
`;

export const CREATE_GOAL = gql`
  mutation createGoalV1(
    $goal: Float, 
    $id_seller: Int, 
    $type: String, 
    $id_type: Int, 
    $date: String, 
    $company: String
  ) {
    createGoalV1(
      goal: $goal, 
      id_seller: $id_seller,
      type: $type,
      id_type: $id_type,
      date: $date, 
      company: $company
    ) {
      ...CreateGoalObject
    }
  }
  ${CREATE_GOAL_OBJECT}
`;

export const UPDATE_GOAL = gql`
  mutation updateGoalV1(
    $goal: Float,
    $id_seller: Int,
    $type: String,
    $id_type: Int,
    $date: String,
    $company: String
  ) {
    updateGoalV1(
      goal: $goal,
      id_seller: $id_seller,
      type: $type,
      id_type: $id_type,
      date: $date,
      company: $company
    ) {
      ...GoalObject
    }
  }
  ${GOAL_OBJECT}
`;

export const CREATE_DRIVER = gql`
  mutation createDriverV1(
    $invoice: String!,
    $chofer: String!,
    $cin: String!,
    $placa: String!,
    $permiso_rasda: String!,
    $destino: String!,
    $peso_neto: String!,
    $tasa_c: Float!,
    $son: String!,
    $company: String
  ) {
    createDriverV1(
      invoice: $invoice,
      chofer: $chofer,
      cin: $cin,
      placa: $placa,
      permiso_rasda: $permiso_rasda,
      destino: $destino,
      peso_neto: $peso_neto,
      tasa_c: $tasa_c,
      son: $son,
      company: $company
    ) {
      ...CreateDriver
    }
  }
  ${CREATE_DRIVER_OBJECT}
`;

//Quotations mutation
export const CREATE_QUOTATION = gql`
  mutation createQuotation(
    $tax: Float!,
    $id_cliente: Int!,
    $codigo: [String!],
    $cantidad: [Float!],
    $precio: [Float!],
    $descuento: [Float!],
    $subtotal: [Float!],
    $total: [Float!],
    $id_almacen: [Int!],
    $createdAt: String!,
    $dueDate: String!,
    $id_seller: ID,
    $tax_incl: String!,
    $id_user: Int,
    $positive_balance: Float,
    $company: String,
    $observations: String
  ) {
    createQuotation(
      tax: $tax,
      id_cliente: $id_cliente,
      codigo: $codigo,
      cantidad: $cantidad,
      precio: $precio,
      descuento: $descuento,
      subtotal: $subtotal,
      total: $total,
      id_almacen: $id_almacen,
      createdAt: $createdAt,
      dueDate: $dueDate,
      id_seller: $id_seller,
      tax_incl: $tax_incl,
      id_user: $id_user,
      positive_balance: $positive_balance,
      company: $company,
      observations: $observations
    ) {
      ...CreateQuotationObject
    }
  }
  ${CREATE_QUOTATION_OBJECT}
`;

export const UPDATE_QUOTATION_STATUS = gql`
  mutation updateStatus(
    $id_factura: ID!,
    $rejectQ: Boolean,
    $deliveryQ: Boolean,
    $delivery_date: String,
    $invoiceQ: Boolean,
    $invoiced_date: String,
    $fromLot: Boolean,
    $id_user: Int,
    $company: String
  ) {
    updateStatus(
      id_factura: $id_factura,
      rejectQ: $rejectQ,
      deliveryQ: $deliveryQ,
      delivery_date: $delivery_date,
      invoiceQ: $invoiceQ,
      invoiced_date: $invoiced_date,
      fromLot: $fromLot,
      id_user: $id_user,
      company: $company
    ) {
      ...updateStatusQObject
    }
  }
  ${UPDATE_STATUS_QUOTATION_OBJECT}
`;

export const UPDATE_STATUS_INVOICE = gql`
  mutation updateStatusInvoiced(
    $id_factura: ID!,
    $pendingSeller: Boolean,
    $cancelled: Boolean,
    $file: String,
    $company: String
  ) {
    updateStatusInvoiced(
      id_factura: $id_factura,
      pendingSeller: $pendingSeller,
      cancelled: $cancelled,
      file: $file,
      company: $company
    ) {
      ...updateStatusQObject
    }
  }
  ${UPDATE_STATUS_QUOTATION_OBJECT}
`;

export const UPDATE_STATUS_PURCHASE = gql`
  mutation updateStatusPurchased(
    $id_invoice: ID!,
    $cancelled: Boolean,
    $file: String,
    $company: String
  ) {
    updateStatusPurchased(
      id_invoice: $id_invoice,
      cancelled: $cancelled,
      file: $file,
      company: $company
    ) {
      ...updateStatusPObject
    }
  }
  ${UPDATE_STATUS_PURCHASE_OBJECT}
`;

export const UPDATE_QUOTATION = gql`
  mutation updateQuotation(
    $id_factura: ID!,
    $tax: Float,
    $id_cliente: Int,
    $id_venta: [Int!],
    $codigo: [String],
    $id_ventaDelete: [Int!],
    $cantidad: [Float],
    $precio: [Float],
    $descuento: [Float],
    $subtotal: [Float],
    $total: [Float],
    $id_almacen: [Int],
    $createdAt: String,
    $dueDate: String!,
    $id_seller: ID,
    $tax_incl: String!,
    $id_user: Int,
    $positive_balance: Float,
    $company: String
  ) {
    updateQuotation(
      id_factura: $id_factura,
      tax: $tax,
      id_cliente: $id_cliente,
      id_venta: $id_venta,
      id_ventaDelete: $id_ventaDelete,
      codigo: $codigo,
      cantidad: $cantidad,
      precio: $precio,
      descuento: $descuento,
      subtotal: $subtotal,
      total: $total,
      id_almacen: $id_almacen,
      createdAt: $createdAt,
      dueDate: $dueDate,
      id_seller: $id_seller,
      tax_incl: $tax_incl,
      id_user: $id_user,
      positive_balance: $positive_balance,
      company: $company
    ) {
      ...QuotationsObject
    }
  }
  ${QUOTATIONS_OBJECT}
`;

export const CREATE_NOTE = gql`
  mutation createNote(
    $note_fact: Int!,
    $id_warehouse: [Int!]
    $id_articulo: [Int!],
    $codigo: [String],
    $cantidad: [Float!],
    $tipo_nota: String!,
    $concepto: String!,
    $precio: [Float!],
    $subtotal: [Float!],
    $descuento: [Float!],
    $total: Float!,
    $createAt: String!,
    $dueDate: String!,
    $company: String,
    $id_user: Int
  ) {
    createNote(
      note_fact: $note_fact,
      id_warehouse: $id_warehouse,
      id_articulo: $id_articulo,
      codigo: $codigo,
      cantidad: $cantidad,
      tipo_nota: $tipo_nota,
      concepto: $concepto,
      precio: $precio,
      subtotal: $subtotal,
      descuento: $descuento,
      total: $total,
      createAt: $createAt,
      dueDate: $dueDate,
      company: $company,
      id_user: $id_user
    ) {
      ...NoteObject
    }
  }
  ${NOTE_OBJECT}
`;
export const UPDATE_POSITIVE_BALANCE_INVOICE = gql`
  mutation updatePositiveBalanceInvoice(
    $id_factura: ID!,
    $positive_balance: Float!,
    $company: String
  ) {
    updatePositiveBalanceInvoice(
      id_factura: $id_factura,
      positive_balance: $positive_balance,
      company: $company
    ) {
      ...CreateQuotationObject
    }
  }
  ${CREATE_QUOTATION_OBJECT}
`;

//Customer mutation
export const CREATE_CUSTOMER = gql`
  mutation createCustomer(
    $doc_nit: String!,
    $id_docs: ID,
    $nombre: String!,
    $direccion: String!,
    $telefono: String!,
    $email: String!,
    $id_country: Int!,
    $city: String!,
    $coordinates: String,
    $RUT: String,
    $id_contributor: ID,
    $id_seller: ID,
    $company: String,
    $id_user: Int,
    $id_segment: ID,
    $positive_balance: Float,
    $id_customerType: ID,
    $id_userSeller: ID,
    $verification_digit: Int,
  ) {
    createCustomer(
      doc_nit: $doc_nit,
      id_docs: $id_docs,
      nombre: $nombre,
      direccion: $direccion,
      telefono: $telefono,
      email: $email,
      id_country: $id_country,
      city: $city,
      coordinates: $coordinates,
      RUT: $RUT,
      id_contributor: $id_contributor
      id_seller: $id_seller,
      company: $company,
      id_user: $id_user,
      id_segment: $id_segment,
      positive_balance: $positive_balance,
      id_customerType: $id_customerType,
      id_userSeller: $id_userSeller,
      verification_digit: $verification_digit
    ) {
      ...RequestCustomerObj
    }
  }
  ${REQ_CUSTOMER_OBJECT}
`;
export const CREATE_CUSTOMER_PROSPECTO = gql`
  mutation createCustomerProspecto(
    $doc_nit: String,
    $id_docs: ID,
    $nombre: String,
    $direccion: String,
    $telefono: String,
    $email: String,
    $id_country: Int,
    $city: String,
    $coordinates: String,
    $RUT: String,
    $id_contributor: ID,
    $id_seller: ID,
    $company: String,
    $id_user: Int,
    $id_segment: ID,
    $positive_balance: Float,
    $id_customerType: ID,
    $id_userSeller: ID,
    $verification_digit: Int,
    $observations: String
  ) {
    createCustomerProspecto(
      doc_nit: $doc_nit,
      id_docs: $id_docs,
      nombre: $nombre,
      direccion: $direccion,
      telefono: $telefono,
      email: $email,
      id_country: $id_country,
      city: $city,
      coordinates: $coordinates,
      RUT: $RUT,
      id_contributor: $id_contributor,
      id_seller: $id_seller,
      company: $company,
      id_user: $id_user,
      id_segment: $id_segment,
      positive_balance: $positive_balance,
      id_customerType: $id_customerType,
      id_userSeller: $id_userSeller,
      verification_digit: $verification_digit,
      observations: $observations
    ) {
      ...RequestCustomerObj
    }
  }
  ${REQ_CUSTOMER_OBJECT}
`;

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer(
    $id_cliente: ID!,
    $doc_nit: String,
    $id_docs: ID,
    $nombre: String,
    $direccion: String,
    $telefono: String,
    $email: String,
    $id_country: Int,
    $city: String,
    $coordinates: String,
    $RUT: String,
    $id_contributor: ID,
    $id_seller: ID,
    $id_segment: ID,
    $positive_balance: Float,
    $id_customerType: ID,
    $id_userSeller: ID,
    $verification_digit: Int,
    $company: String
  ) {
    updateCustomer(
      id_cliente: $id_cliente,
      doc_nit: $doc_nit,
      id_docs: $id_docs,
      nombre: $nombre,
      direccion: $direccion,
      telefono: $telefono,
      email: $email,
      id_country: $id_country,
      city: $city,
      coordinates: $coordinates,
      RUT: $RUT,
      id_contributor: $id_contributor
      id_seller: $id_seller,
      id_segment: $id_segment,
      positive_balance: $positive_balance,
      id_customerType: $id_customerType,
      id_userSeller: $id_userSeller,
      verification_digit: $verification_digit,
      company: $company
    ) {
      ...RequestCustomerObj
    }
  }
  ${REQ_CUSTOMER_OBJECT}
`;

export const UPDATE_DELIVERY = gql`
  mutation updateDelivery(
    $id_cliente: ID!,
    $delivery: String!,
    $company: String
  ) {
    updateDelivery(
      id_cliente: $id_cliente,
      delivery: $delivery,
      company: $company
    ) {
      ...RequestCustomerObj
    }
  }
  ${REQ_CUSTOMER_OBJECT}
`;

export const UPDATE_POSITIVE_BALANCE = gql`
  mutation updatePositiveBalance(
    $id_cliente: ID!,
    $positive_balance: Float!,
    $company: String
  ) {
    updatePositiveBalance(
      id_cliente: $id_cliente,
      positive_balance: $positive_balance,
      company: $company
    ) {
      ...RequestCustomerObj
    }
  }
  ${REQ_CUSTOMER_OBJECT}
`;

//Article mutation
export const CREATE_ARTICLE = gql`
  mutation registerArticle(
    $codigo: String!,
    $descripcion: String!,
    $presentacion: String!,
    $costo: Float!,
    $cantidad: Float!,
    $grupo: Int!,
    $unidad: String!,
    $almacen: Int!,
    $tax: Int!,
    $tipo: Int!,
    $unidad2: Int!,
    $id_type2: Int,
    $alternativeCodes: String,
    $quantityInitial: Float!,
    $valueInitial: Float!,
    $company: String
  ) {
    createArticle(
      codigo: $codigo,
      descripcion: $descripcion,
      presentacion: $presentacion,
      costo: $costo,
      cantidad: $cantidad,
      grupo: $grupo,
      unidad: $unidad,
      almacen: $almacen,
      tax: $tax,
      tipo: $tipo,
      unidad2: $unidad2,
      id_type2: $id_type2,
      alternativeCodes: $alternativeCodes,
      quantityInitial: $quantityInitial,
      valueInitial: $valueInitial,
      company: $company
    ) {
      ...ArticlesObject
    }
  }
  ${ARTICLES_OBJECT}
`;

export const UPDATE_ARTICLE = gql`
  mutation updateArticle(
    $id_articulo: ID!,
    $codigo: String!,
    $descripcion: String!,
    $presentacion: String!,
    $costo: Float!,
    $cantidad: Float!,
    $grupo: Int!,
    $unidad: String!,
    $tax: Int!,
    $tipo: Int!,
    $unidad2: Int!,
    $id_type2: Int,
    $alternativeCodes: String,
    $company: String
  ) {
    updateArticle(
      id_articulo: $id_articulo,
      codigo: $codigo,
      descripcion: $descripcion,
      presentacion: $presentacion,
      costo: $costo,
      cantidad: $cantidad,
      grupo: $grupo,
      unidad: $unidad,
      tax: $tax,
      tipo: $tipo,
      unidad2: $unidad2,
      id_type2: $id_type2,
      alternativeCodes: $alternativeCodes,
      company: $company
    ) {
      ...ArticlesObject
    }
  }
  ${ARTICLES_OBJECT}
`;

export const UPDATE_AMOUNT_VALUE_BY_ARTICLE = gql`
  mutation updateAmountAndValueAccumulated(
    $code: String,
    $cumulative_amount: Float,
    $cumulative_value: Float,
    $company: String
  ) {
    updateAmountAndValueAccumulated(
      code: $code,
      cumulative_amount: $cumulative_amount,
      cumulative_value: $cumulative_value,
      company: $company
    ) {
      ...ArticlesObject
    }
  }
  ${ARTICLES_OBJECT}
`;

/////////////////////////////////////////////////////////////////////
//login Mutation
export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      user {
        id,
        name,
        email,
        roles {
          Id_rol
          name
        }
        companies {
          Id_company
          name
          NIT
          external_electronic_invoicing
          internal_electronic_invoicing
          countries {
            id_country
            name
            iso
            phone_code
          }
        }
      }
      token
    }
  }
`;
//User mutation
export const CREATE_USER = gql`
  mutation createUser(
    $document: Float!,
    $name: String!,
    $email: String!,
    $username: String!,
    $password: String!,
    $id_rol: Int!,
    $id_docs: ID,
    $id_company: Int!,
    $id_prefix: Int
  ) {
    registerUser(
      document: $document,
      name: $name,
      email: $email,
      username: $username,
      password: $password,
      Id_rol: $id_rol,
      id_docs: $id_docs,
      Id_company: $id_company,
      id_prefix: $id_prefix
    ) {
      ...UserTokenObject      
    }
  }
  ${TOKEN_USER_OBJECT}
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!,
    $document: Float,
    $name: String,
    $email: String,
    $username: String,
    $id_rol: Int,
    $status: Int,
    $id_docs: ID,
  ) {
    updateUser(
      id: $id,
      document: $document,
      name: $name,
      email: $email,
      username: $username,
      Id_rol: $id_rol,
      status: $status,
      id_docs: $id_docs,
    ) {
      ...UsersObject
    }
  }
  ${USER_OBJECT}
`;

export const ADD_USER_COMPANY = gql`
  mutation addCompany($id_user: Int!, $id_company: Int!, $id_prefix: Int) {
    addCompany(id_user: $id_user, id_company: $id_company, id_prefix: $id_prefix) {
      ...CompanyUserObject
    }
  }
  ${COMPANY_USER}
`;

export const REMOVE_USER_COMPANY = gql`
  mutation removeCompany($id_user: Int!, $id_company: Int!) {
    removeCompany(id_user: $id_user, id_company: $id_company) {
      ...CompanyUserObject
    }
  }
  ${COMPANY_USER}
`;

export const UPDATE_PASS = gql`
  mutation updatePassword($id_user: Int!, $password: String!) {
    updatePassword(id_user: $id_user, password: $password) {
      ...UsersObject
    }
  }
  ${USER_OBJECT}
`;
