import { Injectable } from "@angular/core";
import { ITransfers } from "src/app/@interfaces/transfers.interface";
import { ReportsService } from "../../reports.service";
import { PortfolioService } from "src/app/@pages/portfolio/portfolio.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import {
  IInvoicePayments,
  IPaymentsList,
  IPurchasePayments,
} from "src/app/@interfaces/payments.interface";
import {
  IDailyInvoicePaymentDetails
} from "src/app/@interfaces/Reports/daily-invoice-payments.interface";
import { TranslateService } from "@ngx-translate/core";
import { IVentaQuery } from "src/app/@interfaces/venta.interface";
import { IQuota } from "src/app/@interfaces/quotation.interface";
import { ITotalsByDepartamentDetails } from "src/app/@interfaces/Reports/totals-by-departament.interface";
import { ITreasury } from "src/app/@interfaces/treasury.interface";

@Injectable({
  providedIn: "root",
})
export class OperationalReportingService {
  unsubscribe$ = new Subject();
  constructor(
    private reportsService: ReportsService,
    private portfolioService: PortfolioService,
    private translate: TranslateService
  ) {}

  async getCashierList(id_user: number) {
    return new Promise<IPaymentsList[]>((resolve, reject) => {
      this.portfolioService
        .getCashierListByUser(id_user)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          resolve(result);
        });
    });
  }

  async getBankList(id_user: number) {
    return new Promise<IPaymentsList[]>((resolve, reject) => {
      this.portfolioService
        .getBankListByUser(id_user)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          resolve(result);
        });
    });
  }

  async getDailyInvoicesAndPayments(filterData: any) {
    return new Promise<IInvoicePayments[]>((resolve, reject) => {
      this.reportsService
        .getDailyInvoicesAndPayments(
          filterData.paymentIds,
          filterData.initialDate,
          filterData.endDate
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          resolve(result);
        });
    });
  }

  async getDailyPurchasesAndPayments(filterData: any) {
    return new Promise<IPurchasePayments[]>((resolve, reject) => {
      this.reportsService
        .getDailyPurchasesAndPayments(
          filterData.cashierIds,
          filterData.initialDate,
          filterData.endDate
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          resolve(result);
        });
    });
  }

  async getDailyTransfers(filterData: any) {
    return new Promise<ITransfers[]>((resolve, reject) => {
      this.reportsService
        .getDailyTransfers(
          filterData.cashierIds,
          filterData.initialDate,
          filterData.endDate
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          resolve(result);
        });
    });
  }

  async getDailyAdvancePayments(filterData: any) {
    return new Promise<ITreasury[]>((resolve, reject) => {
      this.reportsService
        .getDailyAdvancePayments(
          filterData.cashierIds,
          filterData.initialDate,
          filterData.endDate
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          resolve(result);
        });
    });
  }

  async getLastDaysInvoicePaymentAccumulatedValue(filterData: any) {
    return new Promise<number>((resolve, reject) => {
      this.reportsService
        .getLastDaysInvoicePaymentAccumulatedValue(
          filterData.cashierIds,
          filterData.initialDate
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          resolve(result);
        });
    });
  }

  async getLastDaysPurchasePaymentAccumulatedValue(filterData: any) {
    return new Promise<number>((resolve, reject) => {
      this.reportsService
        .getLastDaysPurchasePaymentAccumulatedValue(
          filterData.cashierIds,
          filterData.initialDate
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          resolve(result);
        });
    });
  }

  async getLastDaysTransferAccumulatedValue(filterData: any) {
    return new Promise<number>((resolve, reject) => {
      this.reportsService
        .getLastDaysTransferAccumulatedValue(
          filterData.cashierIds,
          filterData.initialDate
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          resolve(result);
        });
    });
  }

  async getLastDaysAdvancePaymentAccumulatedValue(filterData: any) {
    return new Promise<number>((resolve, reject) => {
      this.reportsService
        .getLastDaysAdvancePaymentAccumulatedValue(
          filterData.cashierIds,
          filterData.initialDate
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          resolve(result);
        });
    });
  }

  combineDetailsByInvoice(dailyDetails: IDailyInvoicePaymentDetails[]) {
    const combinedDetails: {
      [invoiceNumber: string]: IDailyInvoicePaymentDetails;
    } = {};
    dailyDetails.forEach((detail) => {
      const { invoiceNumber, invoiceValue, ...rest } = detail;
      if (combinedDetails[invoiceNumber]) {
        combinedDetails[invoiceNumber] = {
          ...combinedDetails[invoiceNumber],
          ...rest,
          invoiceValue: combinedDetails[invoiceNumber].invoiceValue,
          cashPayment:
            combinedDetails[invoiceNumber].cashPayment + detail.cashPayment,
          cardPayment:
            combinedDetails[invoiceNumber].cardPayment + detail.cardPayment,
          bankPayment:
            combinedDetails[invoiceNumber].bankPayment + detail.bankPayment,
          creditPayment:
            combinedDetails[invoiceNumber].creditPayment + detail.creditPayment,
          paymentCrossAdvance:
            combinedDetails[invoiceNumber].paymentCrossAdvance +
            detail.paymentCrossAdvance,
        };
      } else {
        combinedDetails[invoiceNumber] = { ...detail };
      }
    });
    const combinedDetailsArray: IDailyInvoicePaymentDetails[] =
      Object.values(combinedDetails);
    return combinedDetailsArray;
  }

  combineDetailsByDepartament(details: ITotalsByDepartamentDetails[]) {
    const combinedDetails: {
      [departamentDescription: string]: ITotalsByDepartamentDetails;
    } = {};
    details.forEach((detail) => {
      const { departamentDescription, fee, ...rest } = detail;
      if (combinedDetails[departamentDescription]) {
        combinedDetails[departamentDescription] = {
          ...combinedDetails[departamentDescription],
          ...rest,
          fee: combinedDetails[departamentDescription].fee,
          salesByFee:
            combinedDetails[departamentDescription].salesByFee +
            detail.salesByFee,
          discountsOrFees:
            combinedDetails[departamentDescription].discountsOrFees +
            detail.discountsOrFees,
          consumptionTax:
            combinedDetails[departamentDescription].consumptionTax +
            detail.consumptionTax,
          taxValue:
            combinedDetails[departamentDescription].taxValue + detail.taxValue,
          netValue:
            combinedDetails[departamentDescription].netValue + detail.netValue,
        };
      } else {
        combinedDetails[departamentDescription] = { ...detail };
      }
    });
    const combinedDetailsArray: ITotalsByDepartamentDetails[] =
      Object.values(combinedDetails);
    return combinedDetailsArray;
  }

  verifyPaymentType(element: IInvoicePayments) {
    return element.paymentType[0].type === "Cashier" ? true : false;
  }

  verityPaymentWidhAdvance(element: IInvoicePayments) {
    return element.treasury.length > 0 ? true : false;
  }

  verifyBankIsCard(element: IInvoicePayments) {
    return element.paymentType[0].description
      .toLocaleUpperCase()
      .includes(this.translate.instant("DATAPHONE"));
  }

  getInvoiceTotal(invoice: any) {
    return invoice.venta[0].total.lenght > 0
      ? invoice.venta[0].total[0]
      : invoice.venta[0].total;
  }

  converseDates(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return (month + "/" + day + "/" + year).toLocaleUpperCase();
  }

  getTaxPercent(newSale: IVentaQuery, newInvoice: IQuota) {
    if (newSale.articulo[0].tax![0].value === 0) {
      return newInvoice.tax / 100;
    } else {
      return newSale.articulo[0].tax![0].value;
    }
  }

  validTaxedOperation(taxPercent: number): string {
    return taxPercent > 0
      ? this.translate.instant("Taxed")
      : this.translate.instant("Excluded");
  }

  getTaxTypeValue(totalsByDepartament: ITotalsByDepartamentDetails) {
    return totalsByDepartament.operation === this.translate.instant("Taxed")
      ? totalsByDepartament.taxValue
      : 0;
  }

  validTaxType(totalsByDepartament: ITotalsByDepartamentDetails, type: string) {
    return totalsByDepartament.operation === this.translate.instant(type)
      ? totalsByDepartament.salesByFee
      : 0;
  }

  calculateAverageTaxed(totalSalesByFee: number, taxedValue: number) {
    const multiply = taxedValue * 100;
    const divide = multiply / totalSalesByFee;
    return Number.isNaN(divide) || !Number.isFinite(divide) ? 0 : parseFloat(divide.toFixed(2));
  }
}
