<mat-dialog-content> 
    <form >
        <h2><strong>{{'Article Details' | translate}}</strong></h2>
        <div *ngIf="article.warehouse_values!.length > 0">
            <table class="table table-bordered">
                <thead class="thead-dark">
                    <tr>
                        <th>
                            <mat-label>No.</mat-label>
                        </th>
                        <th>
                            <mat-label>{{'Barcode' | translate}}</mat-label>
                        </th>
                        <th>
                            <mat-label>{{'Warehouse' | translate}}</mat-label>
                        </th>
                        <th>
                            <mat-label>{{'Quantity' | translate}}</mat-label>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let details of article.warehouse_values; let i = index">
                        <td>
                            {{i+1}}
                        </td>
                        <td>
                            {{article.codigo}}
                        </td>
                        <td>
                            {{article.almacen![i].nombre}}
                        </td>
                        <td>
                            {{details.avaliable_quantity}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="article.warehouse_values!.length <= 0" class="alert alert-danger">
            {{'No results for this Article' | translate}}
        </div>
        <div class="div-center">
            <button mat-raised-button [mat-dialog-close]>{{'Close' | translate}}</button>
        </div>
    </form>
    </mat-dialog-content>
    
    <ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)">
        <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
    </ngx-spinner>
    
